import { RegionCode, PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { log } from "../../utils/browser-logger";

export type RegionCodeType = RegionCode;
enum FormatPhoneType {
  SPECIAL = "SPECIAL",
}

export type PhoneNumberFormatType = FormatPhoneType | PhoneNumberFormat;

export type FormatTypes = {
  E164: typeof PhoneNumberFormat.E164;
  INTERNATIONAL: typeof PhoneNumberFormat.INTERNATIONAL;
  NATIONAL: typeof PhoneNumberFormat.NATIONAL;
  RFC3966: typeof PhoneNumberFormat.RFC3966;
  SPECIAL: typeof FormatPhoneType.SPECIAL;
};

export type FormatPhoneOptions = {
  regionCode?: string;
  formatPhone?: PhoneNumberFormatType;
};

export class PhoneNumberFormatter extends PhoneNumberUtil {
  private formatPhone: PhoneNumberFormatType;

  private regionCode: RegionCodeType;

  constructor(
    formatPhone: PhoneNumberFormatType = PhoneNumberFormat.INTERNATIONAL,
    regionCode: RegionCodeType = "US",
  ) {
    super();
    this.formatPhone = formatPhone;
    this.regionCode = regionCode;
  }

  public setFormatPhone = (formatPhone?: PhoneNumberFormatType) => {
    if (formatPhone) {
      this.formatPhone = formatPhone;
    }
  };

  public setRegionCode = (regionCode: RegionCodeType) => {
    this.regionCode = regionCode;
  };

  public getRegionCode = () => {
    return this.regionCode;
  };

  public formatPhoneNumber(phoneNumber: string, options?: FormatPhoneOptions): string {
    const countryCodeInit = options?.regionCode ?? this.regionCode;
    const formatPhoneInit = options?.formatPhone ?? this.formatPhone;

    const parsedNumber = this.parse(phoneNumber, countryCodeInit);
    const isNumberValid = this.isValidNumber(parsedNumber);
    if (isNumberValid) {
      if (formatPhoneInit === FormatPhoneType.SPECIAL) {
        const countryCodeForFormat = parsedNumber.getCountryCode();

        const initFormattedForFRNumber = this.format(parsedNumber, PhoneNumberFormat.NATIONAL);

        const formattedFRNumber = initFormattedForFRNumber.replace(/^(.)/, "($1) ");
        return `+${Number(countryCodeForFormat)} ${formattedFRNumber}`;
      }

      return this.format(parsedNumber, formatPhoneInit);
    }

    log.crucial("format phone number: invalid number", phoneNumber);
    return phoneNumber;
  }

  public getPhoneNumberFormats(): FormatTypes {
    return { ...FormatPhoneType, ...PhoneNumberFormat };
  }
}
